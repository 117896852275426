import { AppState } from './redux/appSlice';
import { GetSessionParams, OrganizationsAccess } from '../../common/types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { oidcAuthService } from './auth/oidcAuthService';

interface MainState {
  app: AppState;
}

export const apiSlice = createApi({
  reducerPath: 'milaPracticeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/api/doctor`,

    prepareHeaders: async (headers, { getState }) => {
      const networkId = ((await getState()) as MainState).app.networkId;
      if (networkId) {
        headers.set('x-mila-network-id', networkId);
      }

      const medOrganizationId = ((await getState()) as MainState).app.medOrganizationId || '';
      if (medOrganizationId) {
        headers.set('x-mila-med-organization-id', medOrganizationId);
      }

      headers.set('Authorization', `Bearer ${await oidcAuthService.getToken()}`);

      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getOrganizations: builder.query<OrganizationsAccess[], string>({
      query: () => ({
        url: `/organizations`,
      }),
    }),
    getSessionId: builder.query<string, GetSessionParams>({
      query: (params: GetSessionParams) => ({
        url: `/session`,
        params: {
          idMedOrganization: params.idMedOrganization,
          idMilaMedOrganizationDoctor: params.idMilaMedOrganizationDoctor,
        },
      }),
    }),
  }),
});

export const { useGetOrganizationsQuery, useGetSessionIdQuery } = apiSlice;
