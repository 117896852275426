import { Buttons } from '../Buttons/Buttons';
import { Image, Layout, Row, Typography } from 'antd';
import NotFound from 'src/common/Icons/NotFound.png';
import React from 'react';
import styles from './styles.module.scss';

interface ErrorPageProps {
  title?: string;
  text?: string;
}

export const ErrorPage = ({ title, text }: ErrorPageProps) => {
  return (
    <Layout className={styles.ErrorPageContainer}>
      <Image preview={false} src={NotFound} alt={``} />
      <Row>
        <Typography className={styles.ErrorPageTitle}>{title}</Typography>
      </Row>
      <Row>
        <Typography className={styles.ErrorPageDescription}>{text}</Typography>
      </Row>
      <Buttons />
    </Layout>
  );
};
