import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AppState {
  networkId?: string;
  medOrganizationId?: string;
  hospitalId?: string;
  snils?: string;
  medOrganizationDoctorId?: string;
}

const initialState: AppState = {
  networkId: '',
  medOrganizationId: '',
  hospitalId: '',
  snils: '',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setNetwork: (state, action: PayloadAction<string>) => {
      state.networkId = action.payload;
    },

    setMedOrganizationId: (state, action: PayloadAction<string>) => {
      state.medOrganizationId = action.payload;
    },

    setHospitalId: (state, action: PayloadAction<string>) => {
      state.hospitalId = action.payload;
    },

    setSnils: (state, action: PayloadAction<string>) => {
      state.snils = action.payload;
    },
    setMedOrganizationDoctorId: (state, action: PayloadAction<string>) => {
      state.medOrganizationDoctorId = action.payload;
    },

    resetApp: () => initialState,
  },
});

export const { setNetwork, setMedOrganizationId, setHospitalId, setSnils, setMedOrganizationDoctorId, resetApp } =
  appSlice.actions;

export const appReducer = appSlice.reducer;
