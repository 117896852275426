import { MicroFrontend } from '../../common/Components/MicroFrontend/MicroFrontend';
import { useAppSelector } from 'src/app/hooks';
import { useGetSessionIdQuery } from '../../app/services/api';
import { useEffect } from "react";

const widgetUrl: string = window.__RUNTIME_CONFIG__.REACT_APP_TM_WIDGETS_URL;

const RequestsPage = () => {
  const moId = useAppSelector((state) => state.app.hospitalId) || '';
  const milaMedOrganizationDoctorId = useAppSelector((state) => state.app.medOrganizationDoctorId) || '';

  const { data: sessionId } = useGetSessionIdQuery({
    idMedOrganization: moId,
    idMilaMedOrganizationDoctor: milaMedOrganizationDoctorId,
  });

  const listener = () => {
    console.log(sessionId);
    try {
      window?.postMessage(
          {
            isActualData: true,
            data: {
              sessionId,
            },
          },
          '*',
      );
    } catch (e) {
      console.error(e);
    }
  };

  const loadIframe = ({ data }: { data: string }) => {
    if (data === 'load') {
      listener();
    }
  };

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener('message', loadIframe, false);
    }
    return () => window.removeEventListener('message', loadIframe, false);
  }, [sessionId]);

  return sessionId ? <MicroFrontend name="TMWidget" host={widgetUrl} /> : <div>Загрузка....</div>;
};
export default RequestsPage;
