import { Button, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import styles from './styles.module.scss';

export const Buttons = () => {
  return (
    <Row className={styles.FormButtonsContainer} gutter={[10, 10]}>
      <Col>
        <Button className={styles.ButtonCancel} htmlType="reset">
          Написать в поддержку
        </Button>
      </Col>
      <Col>
        <Link className={styles.ButtonSubmit} to="/">
          Вернуться на главную
        </Link>
      </Col>
    </Row>
  );
};
